<template>
  <a-layout class="index animated fadeIn">
    <a-card>
      <div class="mb15">
        <span class="mr15">
          <a-button type="primary" v-has:smsPriceAdd="() =>smsPriceAdd('add')">
            添加短信定价
          </a-button>
        </span>
      </div>
      <a-table :pagination="false" :loading="listLoading" bordered rowKey="id" :columns="columns" :data-source="data">
         <template v-slot:num="{ record }">
           <span>{{record.num}} 条</span>
         </template>
         <template v-slot:amount="{ record }">
           <span>{{record.amount}} 元</span>
         </template>
         <template v-slot:unit_price="{ record }">
           <span>{{record.unit_price}} 元 / 条</span>
         </template>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:smsPriceEdit="()=> smsPriceAdd(record)" href="javascript:;">修改</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:smsPriceDel="()=>smsPriceDel(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal :title="(addType == 'add' ?'添加':'修改')+'短信定价'" v-model:visible="visible" :confirm-loading="confirmLoading" @ok="handleOk">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" ref="formCell" :model="createForm" :rules="rules">
      <!-- <a-form-item label="套餐名称:" name="name" ref="name"> -->
      <!-- <a-input v-model:value="createForm.name" placeholder="请输入名称" /> -->
      <!-- </a-form-item> -->
      <a-form-item label="条数:" name="num" ref="num">
        <a-input-number style="width: 100%" v-model:value="createForm.num" placeholder="请输入条数" @change='numInput' />
      </a-form-item>
      <a-form-item label="价格:" name="amount" ref="amount" :help='unit_price > 0 ? unit_price+"元/条,结果保留三位小数实际金额以使用时为主":""'>
        <a-row>
          <a-col :span="22">
            <a-input-number :disabled='!createForm.num' v-model:value="createForm.amount" placeholder="请输入价格" style="width: 100%" @change='countPrice'  :max="999999"/>
          </a-col>
          <a-col :span="2">
            <p class="s_text_r">元</p>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { setPageState, getPageState } from '@/utils/pageState';
import { $iscode, getRouter } from '@/utils/app';
import { getSms, addSms, editSms, delSms } from '@/api/product';
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 10,
};
export default {
  components: { DownOutlined },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      addType: 'add',
      listLoading: false,
      visible: false,
      total: 1,
      confirmLoading: false,
      createForm: {
        name: '',
        num: '',
        amount: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称' }],
        num: [{ required: true, message: '请输入条数' }],
        amount: [{ required: true, message: '请输入价格' }],
      },
      unit_price: 0,
    });
    let columns = [
      // {
      //   title: "套餐名称",
      //   dataIndex: "name",
      //   key: "name",
      // },
      {
        title: '条数',
        dataIndex: 'num',
        key: 'num',
         slots: { customRender: 'num' },
      },
      {
        title: '价格',
        dataIndex: 'amount',
        key: 'amount',
         slots: { customRender: 'amount' },
      },
      {
        title: '元 / 1条',
        dataIndex: 'unit_price',
        key: 'unit_price',
         slots: { customRender: 'unit_price' },
      },
      {
        title: '操作',
        key: 'action',
        width: 120,
        slots: { customRender: 'action' },
      },
    ];
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 页面筛选项重置
    const pageSearchReset = () => {
      search.value = {
        ...defSearch,
        page: search.value.page,
        size: search.value.size,
      };
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.size = size;
      initData(search.value);
    };
    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      let res = await getSms(values)
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        data.value = res.data.data;
        // console.log(res.data.data,11)
        state.total = res.data.total;
      }
      state.listLoading = false;
    };
    // 定义表单REF
    const formCell = ref();
    // 修改类目
    const smsPriceAdd = (value) => {
      if (value == 'add') {
        state.addType = 'add';
        state.unit_price = '';
        state.createForm = {
          name: '',
          num: '',
          amount: '',
        };
      } else {
        state.addType = 'edit';
        state.createForm['id'] = value.id;
        state.createForm['name'] = value.name;
        state.createForm['num'] = value.num;
        state.createForm['amount'] = value.amount;
        countPrice();
      }
      state.visible = true;
    };
    const numInput = () => {
      if (state.addType == 'edit') {
        countPrice();
      }
    };
    const _lodash = inject('_lodash');
    //计算价格
    const countPrice = () => {
      var amount=state.createForm.amount || 0
      var num= state.createForm.num || 0
      state.unit_price =(amount/num).toFixed(3)
      console.log(state.unit_price);
    };
    const $Modal = inject('$Modal');
    // 删除类目
    const smsPriceDel = (value) => {
      $Modal.confirm({
        title: '提示',
        content: '删除后该短信定价将无法恢复，您确定要删除该短信定价吗？',
        centered:true,
        onOk: () => {
          delSms({ id: value.id }).then((res) => {
            if ($iscode(res.data, true)) {
              initData(search.value);
            }
          });
        },
      });
    };
    const $message = inject('$message');
    // 添加或者修改提交信息
    const handleOk = async (e) => {
      state.confirmLoading = true;
      formCell.value
        .validate()
        .then(async (res) => {
          var result;
          if (!state.createForm.id) {
            result = await addSms({ ...state.createForm })
              .then((res) => res.data)
              .catch((error) => error);
          } else {
            result = await editSms({
              id: state.createForm.id,
              ...state.createForm,
            })
              .then((res) => res.data)
              .catch((error) => error);
          }
          if ($iscode(result, true)) {
            initData(search.value);
            state.visible = false;
          }
          state.confirmLoading = false;
        })
        .catch((error) => {
          state.confirmLoading = false;
          $message.error('请完善表单');
        });
    };
    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if (pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      smsPriceDel,
      pageSearchChange,
      pageSearchReset,
      pageCurrentChange,
      pageSizeChange,
      smsPriceAdd,
      handleOk,
      formCell,
      countPrice,
      numInput,
    };
  },
};
</script>
